import React, { useState, useEffect, useRef, createRef } from "react"
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios"
import Breadcrumbs_Interior from "../components/Breadcrumbs/Breadcrumbs_Interior"
import wordsToNumbers from 'words-to-numbers';
import TitleBlock from "../components/TitleBlock/TitleBlock"
import InteriorBanner from "../components/Interior/Banner/DetailBanner"
import ContentBlock from "../components/Interior/Blocks/Content"
import Gallery from "../components/Interior/Gallery/Gallery"
import Reviews from "../components/Home/Reviews/Reviews"
import "../scss/custom.scss"
import { removeDataAttributes } from "../comQueryStructure";
import { google_review_url } from "../components/common/utils";

function InteriorTemplate(props) {
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
    const [testimonials, setTestimonials] = useState([]);
    const designData = removeDataAttributes(props?.data)
    var mypagedata = designData?.glstrapi?.interiorDesigns;
    var data = mypagedata[0];

    var ggl_reviews = [];
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, strapiconfig)// could be from env files
            const reviewdata = removeDataAttributes(data)
            if (reviewdata && reviewdata.length > 0) {
                reviewdata.map((item, index) => {
                    var star_no = wordsToNumbers(item.starRating);
                    if (star_no > 3) {
                        if (item.comment) {
                            ggl_reviews.push(item);
                        }
                    }

                })
            }
            setTestimonials(ggl_reviews);
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    useEffect(() => {
        if (testimonials.length == 0) {
            let url = google_review_url
            getitems(url);
        }
    })

    return (
        <>
        
            <Layout Layout={'Without_Banner'} classNames="Without_Banner interior" Pagename={data?.Title}>
                <SEO title={data && data?.Meta_Title} description={data && data?.Meta_Description?data?.Meta_Description:data?.Description} image={data && data?.Banner_Image?.url} />

                <Helmet />

                {
                    data && <>

                        <div className="page-content static-page interior-details-page">

                            <Breadcrumbs_Interior page_name={data?.Title} Heading={data?.Title} location={props?.location} />
                            {
                                data?.Title && data?.Description && <TitleBlock Title={data?.Title} Description={data?.description_content} />
                            }
                            <InteriorBanner {...data} />
                            <ContentBlock {...data} />
                            <Gallery {...data} />
                            <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /></div>
                        </div>
                    </>
                }

            </Layout>
        </>
    )
}

export const query = graphql`
query InteriorDesigns($slug: String){
  glstrapi {
    interiorDesigns(filters: {Slug: {eq: $slug}}){
      data {
        id
        attributes {
          Title
          Slug
          Video_URL
          Intro
          Description
          Meta_Description
          description_content
          Content
          Tile_Image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          Gallery {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          Banner_Images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
}`


export default InteriorTemplate