/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/master.scss";

import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout = (props) => {

  const [state,setState] = useState({
    showMenu:false
  })




  const handlerClick = () => {
    //console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {  

      site {
        siteMetadata {
          title
        }
      }

    }
  `)

  //console.log("allStrapiAllMenus",data.allStrapiAllMenus);

  return (
    <div className={`${props.classNames?.replace("Rightside_Block_No_Header","Rightside_Block")} ${state.showMenu ? "wrapper open-search-block" : "wrapper"}`}>
      <Header Layout={props.Layout} />
        <main>
          {props.children}
        </main>
      <Footer {...props}/> 
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
